<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="minHeight">
          <div class="row justify-content-between align-items-center mb-4">
            <div class="col-12 col-md-8 col-lg-9 d-flex">
              <base-input
                type="search"
                v-model="searchedManager"
                @input="searchManager()"
                :placeholder="$t('searchManager')"   
                class="w-100 m-auto"
              >
              </base-input>
              <base-button simple type="primary" class="clean-button ml-sm-2" @click="cleanSearch()">
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>

            <div class="col-12 col-md-12 col-lg-3 d-flex justify-content-end">
              <Popover
                  trigger="manual"
                  placement="left"
                  :content="$t('clickAddUser')"
                  popper-class="font-weight-bold shadow-popover"
                  v-model="isPopoverVisible"
              >
                <base-button
                  slot="reference"
                  v-if="isAdmin"
                  type="primary"
                  @click="$router.push({ name: 'newManager' })"
                  >{{ $tc("new") }}</base-button
                >
              </Popover>
            </div>
          </div>
          <base-table
            v-if="!isSpinnerShow"
            :data="managers"
            :columns="managersColumns"
            :columnsSize="columnsSize"
            @clickRow="goToDetailView"
            class="col"
          >
            <template slot="columns">
              <th scope="col">{{ $t("idUser") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("email") }}</th>
              <th scope="col">{{ $t("store") }}</th>
              <th scope="col">{{ $t("phone") }}</th>
            </template>
            <template slot-scope="{ row }">
              <td>{{ row.id }}</td>
              <td>{{ row.name }}</td>
              <td>{{ row.email }}</td>
              <td>{{ row.store.name }}</td>
              <td>{{ row.phone }}</td>
            </template>
          </base-table>
          <base-table
              v-else-if="managers.length === 0"
              :data="[1]"
              class="table-responsive-md"
          >
            <template slot="columns">
              <th scope="col">{{ $t("idUser") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("email") }}</th>
              <th scope="col">{{ $t("store") }}</th>
              <th scope="col">{{ $t("phone") }}</th>
            </template>
            <template>
              <td class="col-3"></td>
              <td class="col-3"></td>
              <td class="col-3"><Spinner parentClasses="justify-content-start"></Spinner></td>
            </template>
          </base-table>
          <template slot="footer">
            <pagination
              v-if="managers.length > 0"
              @turnPage="getItems"
              element="user"
              :perPage="pagination.perPage"
              :lastPage="pagination.lastPage"
              :totalItems="pagination.totalItems"
              :currentPage="currentPage"
            ></pagination>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Card, BaseInput, BaseTable, Pagination, Spinner } from "@/components/index";
import { mapState } from "vuex";
import { Popover } from "element-ui";

export default {
  name: "ManagerList",
  data() {
    return {
      route: "/users",
      pagination: {
        perPage: 10,
        lastPage: 1,
        totalItems: null,
      },
      managers: [],
      managersColumns: ["id user", "name", "email", "store", "contactPhone"],
      columnsSize: [null, null, null],
      isSpinnerShow: true,
      isPopoverVisible: false,
      currentPage: 1,
      searchedManager: '',
      searchTimer: null
    };
  },
  computed: {
    ...mapState(["isAdmin", "defaultTitle"]),
  },
  methods: {
    async getItems(page = 1) {
      this.currentPage = page;
      const params = {
        page: this.currentPage,
        only_managers: 1
      };

      let response = await this.axios.get(this.route, { params });
      if (response && response.data.status === "success") {
        const data = response.data.data.users;
        this.managers = data.data;
        this.pagination.perPage = data.per_page;
        this.pagination.lastPage = data.last_page;
        this.pagination.totalItems = data.total;
        this.isSpinnerShow = false;
        this.isPopoverVisible = this.managers.length === 0;
      }
    },
    searchManager() {
      if(this.searchTimer) {
        clearTimeout( this.searchTimer)
        this.searchTimer = null
      }
      if(this.searchedManager.length > 0){
        this.searchTimer = setTimeout( async()=>{
          this.currentPage = 1;

          const params = {
            page: this.currentPage,
            only_managers: 1,
            query: this.searchedManager
          }
          
          let response = await this.axios.get(this.route, { params });
          if (response && response.data.status === "success") {
            const data = response.data.data.users;
            this.managers = data.data;
            this.pagination.perPage = data.per_page;
            this.pagination.lastPage = data.last_page;
            this.pagination.totalItems = data.total;
            this.isSpinnerShow = false;
          }
        }, 500)
      }
      if(this.searchedManager.length === 0){
        this.searchedManager = null;
        this.getItems()
      }
    },
    cleanSearch() {
      if(this.searchedManager !== null){
        this.searchedManager = null;
        this.getItems();
      }
    },
    goToDetailView(itemId) {
      this.$router.push({ name: "manageManager", params: { id: itemId } });
    },
  },
  created() {
    if (!this.isAdmin) this.$router.push("/");
  },
  mounted() {
    this.getItems();
  },
  metaInfo() {
    return { title: `${this.$tc('user', 0)} - ${this.defaultTitle}` }
  },
  components: {
    Card,
    BaseInput,
    BaseTable,
    Pagination,
    Spinner,
    Popover,
  },
};
</script>

<style>
.minHeight {
  min-height: 90vh !important;
}
.shadow-popover {
  box-shadow: 0 2px 12px 0 rgb(0, 0, 0, 50%);
}
.clean-button{
  height: 38px;
  width: 38px;
  margin: 0;
  margin-left:5px;
  padding: 0;
}
</style>
